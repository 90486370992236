function activateDonate(defaultValue) {
  let donateSwitch = document.getElementsByClassName('button-amount');
  let customAmountWrapper = document.getElementById('customAmountWrapper');
  let customAmount = document.getElementById('customAmount');
  let formAmount = document.getElementById('id_amount');
  let buttonAmount = document.getElementById('button-amount');
  if (defaultValue){
    formAmount.value = defaultValue;
  } else {
    formAmount.value = 25;
  }

  buttonAmount.innerHTML = formAmount.value;

  let maskOptions = {
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: '.',
    min: 3,
    max: 35000,
  }
  let customAmountMask = IMask(customAmount, maskOptions)

  customAmount.addEventListener('input', function(e) {
    if (this.value) {
      formAmount.value = customAmountMask.unmaskedValue;
      buttonAmount.innerHTML = formAmount.value;
    }
  });

  customAmount.setAttribute('disabled', 'disabled');

  let setAmount = function() {
    let donateAmount = this.getAttribute('data-amount');

    if (donateAmount != 'Other') {
      formAmount.value = donateAmount;
      customAmount.setAttribute('disabled', 'disabled');
      customAmountWrapper.setAttribute('hidden', 'true');
    } else {
      formAmount.value = '0';
      //customAmount.value = '0';
      customAmountWrapper.removeAttribute('hidden');
      customAmount.removeAttribute('disabled');
    }
    buttonAmount.innerHTML = formAmount.value;

    /*
    var paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Donation',
        amount: formAmount.value * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    })

    var elements = stripe.elements()
    var prButton = elements.create('paymentRequestButton', {paymentRequest: paymentRequest})

    paymentRequest.canMakePayment().then(function(result){
      if (result) {
        prButton.mount('#paymentRequest')
      } else {
        // prButton.mount('#paymentRequest')
        document.getElementById('paymentRequest').style.display = 'none';
      }
    })*/
  };

  for (let i = 0; i < donateSwitch.length; i++) {
    donateSwitch[i].addEventListener('click', setAmount, false);
  }

  function registerElements(elements, formName) {
    let formClass = `.${ formName}`;
    let formWrapper = document.querySelector(formClass);

    let form = formWrapper.querySelector('form');
    let error = form.querySelector('.form-errors');
    let errorMessage = error.querySelector('.form-error-messages');

    function enableInputs() {
      Array.prototype.forEach.call(
        form.querySelectorAll(
          'input[type=\'text\'], input[type=\'email\'], input[type=\'tel\']'
        ),
        (input) => {
          input.removeAttribute('disabled');
        }
      );
    }

    function disableInputs() {
      console.log("Disable inputs")
      Array.prototype.forEach.call(
        form.querySelectorAll(
          'input[type=\'text\'], input[type=\'email\'], input[type=\'tel\']'
        ),
        (input) => {
          input.setAttribute('disabled', 'true');
        }
      );
    }

    function triggerBrowserValidation() {
      let submit = document.createElement('input');
      submit.type = 'submit';
      submit.style.display = 'none';
      form.appendChild(submit);
      submit.click();
      submit.remove();
    }

    function stripeTokenHandler(token) {
      // Insert the token ID into the form so it gets submitted to the server
      let hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);
      // Submit the form
      enableInputs();
      form.submit();

    }


    let savedErrors = {};
    elements.forEach((element, idx) => {
      element.on('change', (event) => {
        if (event.error) {
          error.classList.add('visible');
          savedErrors[idx] = event.error.message;
          errorMessage.innerText = event.error.message;
        } else {
          savedErrors[idx] = null;

          let nextError = Object.keys(savedErrors)
            .sort()
            .reduce((maybeFoundError, key) => {
              return maybeFoundError || savedErrors[key];
            }, null);

          if (nextError) {
            errorMessage.innerText = nextError;
          } else {
            error.classList.remove('visible');
          }
        }
      });
    });

    // Listen on the form's 'submit' handler...
    form.addEventListener('submit', (e) => {
      e.preventDefault();

      // Show a loading screen...
      formWrapper.classList.add('submitting');

      // Disable all inputs.
      disableInputs();

      // Gather additional customer data we may have collected in our form.
      let name = form.querySelector('#id_fullname');
      let address1 = form.querySelector('#id_address');
      let email = form.querySelector('#id_email');
      let phone = form.querySelector('#id_phone');
      let city = form.querySelector('#id_city');
      let state = form.querySelector('#id_state');
      let zip = form.querySelector('#id_zip');
      let additionalData = {
        name: name ? name.value : undefined,
        address_line1: address1 ? address1.value : undefined,
        address_city: city ? city.value : undefined,
        address_state: state ? state.value : undefined,
        address_zip: zip ? zip.value : undefined,
      };


      stripe.createToken(elements[0], additionalData).then((result) => {

        if (result.token) {
          stripeTokenHandler(result.token);
        } else {
          formWrapper.classList.remove('submitting');
          enableInputs();
          error.classList.add('visible');
          errorMessage.innerText = result.error.message;
        }
      });
    });
  }

  (function() {
    let elements = stripe.elements();

    let elementStyles = {
        base: {
          'iconColor': '#000',
          'color': '#000',
          'fontWeight': '500',
          'fontFamily': 'Source Code Pro, Consolas, Menlo, monospace',
          'fontSize': '14px',
          'fontSmoothing': 'antialiased',

          '::placeholder': {
            'fontSize': '12px',
            'color': '#9d9fa2',
            'textTransform': 'uppercase',
          },
          ':-webkit-autofill': {
            'color': '#3e3e3f'
          }
        },
        invalid: {
          'iconColor': '#ff7175',
          'color': '#ff7175',
          'borderColor': '#ff7175'
        }
      }



    let elementClasses = {
      focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
    }
    let cardNumber = elements.create('card', {
      style: elementStyles,
    classes: elementClasses,
    });
    cardNumber.mount('#donateFormCard');

    registerElements([cardNumber], 'donate-form-wrapper');
  }());



  let phoneInput = document.getElementById("id_phone")
  let phoneMaskOptions = {
    mask: '(000) 000-0000'
  }
  let phoneMask = IMask(phoneInput, phoneMaskOptions)

  let zipInput = document.getElementById("id_zip")
  let zipMaskOptions = {
    mask: "00000[-0000]"
  }
  let zipMask = IMask(zipInput, zipMaskOptions)

}
